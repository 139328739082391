import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-lupinha-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  @Input() label: string;
  @Input() name: string;
  @Input() maskInput: string;
  @Input() placeholder: string;
  @Input() value: string;
  @Input() marginTop = 'mt-4';
  @Input() inputType: string;
  @Input() isPassword: boolean = false;
  @Input() labelColor = '#fff';

  @Output() inputChange: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  public showPassword: boolean = false;

  public handleShowPassword(): void {
    this.showPassword = !this.showPassword;
    this.inputType = this.showPassword ? 'text' : 'password';
  }
}