import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LowercaseUrlRedirectorGuard } from './shared/guard/lowercaseUrlRedirectorGuard.guard';

const routes: Routes = [
  { loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule), path: '', canActivate: [LowercaseUrlRedirectorGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [LowercaseUrlRedirectorGuard]
})
export class AppRoutingModule { }