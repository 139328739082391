import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { FunctionsService } from './shared/services/functions.service';
import { MatPaginationIntlService } from './shared/services/mat-pagination-intl.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ComponentsModule,
    BrowserAnimationsModule
  ],
  providers: [
    FunctionsService,
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginationIntlService
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }