import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { FunctionsService } from 'src/app/shared/services/functions.service';

@Component({
  selector: 'app-lupinha-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  public form: any = {
    name: '',
    email: '',
    company: '',
    phone: ''
  }

  constructor(
    private _http: HttpClient,
    private toastr: ToastrService,
    private _functions: FunctionsService
  ) { }

  ngOnInit(): void { }

  public checkForm() {
    const phone = this.form.phone;
    if (phone) this.form.phone = this._functions.removeCharacters(phone);

    if (!this.form.name) {
      this.toastr.warning('Por favor preencha o campo.', 'Nome Completo');
      return false;

    } else if (!this.form.email) {
      this.toastr.warning('Por favor preencha o campo.', 'E-mail');
      return false;

    } else if (!this._functions.validateEmail(this.form.email)) {
      this.toastr.error('O campo e-mail não contém um endereço de e-mail válido.', 'Inválido');
      return false;

    } else if (!this.form.company) {
      this.toastr.warning('Por favor preencha o campo.', 'Empresa');
      return false;

    } else if (!this.form.phone) {
      this.toastr.warning('Por favor preencha o campo.', 'Celular');
      return false;

    } else if (!this._functions.validatePhone(this.form.phone)) {
      this.toastr.error('O campo celular não é válido.', 'Inválido');
      return false;
    }

    this.addRow();
  }

  addRow() {
    const link = 'https://lupinha.org.br';

    let f = this.form;
    f.phone = this._functions.maskPhone(f.phone);

    const now = moment().format('DD/MM/YYYY HH:mm')

    const rowData = {
      name: f.name,
      email: f.email,
      company: f.company,
      phone: f.phone,
      link: link,
      now: now
    }

    this._http.post('https://lupinha.org.br/api/submit-spreadsheet', rowData)
      .subscribe(
        () => {
          this.toastr.success('Em breve retornaremos a sua solicitação.', 'Obrigado pelo seu contato!');
        },
        error => {
          console.error('Erro ao enviar o formulário:', error);
        }
      );

    this.resetForm();
  }

  private resetForm(): void {
    this.form = {
      name: '',
      email: '',
      company: '',
      phone: ''
    }
  }
}