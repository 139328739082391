import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nutritional-table',
  templateUrl: './nutritional-table.component.html',
  styleUrls: ['./nutritional-table.component.scss']
})
export class NutritionalTableComponent implements OnInit {

  @Input() data: any;
  @Input() portion: string;
  @Input() additionalInformation: string;

  constructor() { }

  ngOnInit(): void { }
}
