import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileService } from './file.service';
import { ConfirmDeleteComponent } from '../modals/confirm-delete/confirm-delete.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lupinha-attach-file',
  templateUrl: './attach-file.component.html',
  styleUrls: ['./attach-file.component.scss']
})
export class AttachFileComponent implements OnInit {

  @Input() title = '';
  @Input() file: any = null;
  @Input() type: string;
  @Output() onUpload: EventEmitter<any> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private fileService: FileService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void { }

  public handleFileInput(event: any): void {
    const file: File = event.target.files[0];
    const myReader: FileReader = new FileReader();

    if (file) {
      myReader.onloadend = () => {
        this.fileService.uploadImage({
          url: myReader.result,
          folder: this.type === 'photoAd' ? 'Ad' : 'Product'
        }).subscribe((response) => {
          if (response.status === 'success') {
            const body = {
              idCloudinary: response.data.url.public_id,
              url: response.data.url.secure_url,
              type: this.type,
              name: file.name,
            }

            this.file = body;
            this.onUpload.emit(body);
          }
        }, error => {
          this.toastr.error(error?.error?.message || 'Algo deu errado, tente novamente mais tarde.', 'Erro');
        });
      };

      myReader.readAsDataURL(file);
    }
  }

  public handleDelete(): void {
    this.dialog.open(ConfirmDeleteComponent, {
      data: {
        title: 'Excluir Imagem',
        message: 'Você tem certeza que deseja excluir essa imagem?',
        onDelete: () => {
          this.fileService.destroyImage(this.file.idCloudinary).subscribe(() => {
            this.file = null;
          });
        }
      }
    });
  }
}
