import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LowercaseUrlRedirectorGuard implements CanActivate {
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const lowercasePath = state.url.toLowerCase();
        if (state.url !== lowercasePath) {
            this.router.navigateByUrl(lowercasePath);
            return false;
        }
        return true;
    }
}