import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private _url: string;
  private token: string;

  constructor(
    private _http: HttpClient
  ) { 
    this._url = 'https://lupinha.org.br/api';
    this.token = `Bearer ${localStorage.getItem('token')}`;
  }

  public uploadImage(data: any): Observable<any> {
    return this._http.post(`${this._url}/upload`, data, { headers: {
      'Authorization': this.token
    }});
  }

  public destroyImage(publicId: any): Observable<any> {
    return this._http.post(`${this._url}/destroy`, { id: publicId }, { headers: {
      'Authorization': this.token
    }})
  }
}
