import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-products-primary-info',
  templateUrl: './products-primary-info.component.html',
  styleUrls: ['./products-primary-info.component.scss']
})
export class ProductsPrimaryInfoComponent implements OnInit {

  @Input() product: any;

  public isMobile = false;

  constructor() { }

  ngOnInit(): void {
    this.getImage();
  }

  public getImage(): void {
    this.product.coverPhoto = this.product?.images?.filter((image: any) => image.type === 'coverPhoto')[0]?.url;
  }
}