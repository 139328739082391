import { Injectable } from '@angular/core';
import { version } from './../../../../package.json';

@Injectable()
export class FunctionsService {

  public settings: any = {
    appVersion: version,
  }

  constructor() { }

  public getAppVersion(): string {
    return this.settings.appVersion;
  }

  /**
  * Convert base64 to Blob
  */
  public b64toBlob(b64Data, contentType = '', sliceSize = 512): Blob {
    let fileData = b64Data.split(',')[1];

    if (!fileData) {
      fileData = b64Data;
    }

    const byteCharacters = atob(fileData);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  public validateEmail(_email: string): boolean {
    const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regExp2 = /[ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ]/;

    return regExp.test(String(_email).toLowerCase()) && !regExp2.test(String(_email).toLowerCase());
  }

  public validateNameFull(_name) {
    if (_name) {
      const exp = _name.split(' ');
      return exp.length <= 1 ? false : true;

    } else {
      return false;
    }
  }

  public validatePhone(_phone: any) {
    return _phone && (_phone.length === 10 || _phone.length === 11);
  }

  // Generically format the fields
  public fieldFormat(field: string, mask: string) {
    if (!field) return '';

    let booleanMask = false;

    const exp = /-|\.|\/|\(|\)| /g;
    const justNumbersField = field.replace(exp, '');

    let fieldPosition = 0;
    let newValueField = '';
    let maskSize = justNumbersField.length;

    if (justNumbersField) {
      for (let i = 0; i <= maskSize; i++) {
        booleanMask = ((mask.charAt(i) == '-') || (mask.charAt(i) == '.') || (mask.charAt(i) == '/'));
        booleanMask = booleanMask || ((mask.charAt(i) == '(') || (mask.charAt(i) == ')') || (mask.charAt(i) == ' '));

        if (booleanMask) {
          newValueField += mask.charAt(i);
          maskSize++

        } else {
          newValueField += justNumbersField.charAt(fieldPosition);
          fieldPosition++
        }
      }

      field = newValueField;

      return field;

    } else {
      return field;
    }
  }

  public removeCharacters(_value: string): string {
    if (!_value) {
      return '';
    }

    return _value.replace(/[^\d]+/g, '');
  }

  public removeSpecialCharacters(_value: string) {
    if (!_value) {
      return;
    }

    return _value.replace(/[^a-z0-9]/gi, '');
  }

  public emptyObject(obj: any) {
    if (!obj) return true;

    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  }

  public convertFirstLetterWord(text: string) {
    if (!text) return '';

    let convert = '';
    const splitText = text.trim().split(' ');

    splitText.forEach(word => {
      word = word.toLowerCase();

      if (!['', 'a', 'o', 'e', 'as', 'os', 'à', 'aos', 'às', 'ou', 'de', 'do', 'da', 'dos', 'das', 'um', 'uma', 'uns', 'umas', 'em', 'no', 'na', 'né', 'nos',
        'nas', 'num', 'numa', 'nuns', 'numas', 'dum', 'duma', 'duns', 'dumas', 'por', 'pela', 'pelo', 'pelos', 'pelas', 'para', 'pra',
      ].includes(word)) {
        convert += ` ${word.charAt(0).toUpperCase() + word.substr(1)} `;

      } else if (word) {
        convert += ` ${word} `;
      }
    });

    return convert.trim().replace(/  /g, ' ');
  }

  public firstName(_str: string) {
    if (!_str) return '';

    const spl = _str.trim().split(' ');

    return this.convertFirstLetterWord(this.firstToUpper(spl[0]));
  }

  public firstToUpper(_str: string) {
    return _str.charAt(0).toUpperCase() + _str.slice(1);
  }

  public formatCpfOrCnpj(_cpfOrCnpj: string) {
    if (!_cpfOrCnpj) return '';

    if (_cpfOrCnpj.length === 14) {
      _cpfOrCnpj = _cpfOrCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5');

    } else {
      _cpfOrCnpj = _cpfOrCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
    }

    return _cpfOrCnpj;
  }

  public maskPhone(_phone: string) {
    if (!_phone) return '';

    if (_phone.length === 10) return this.fieldFormat(_phone, '(00) 0000-0000');

    if (_phone.length === 11) return this.fieldFormat(_phone, '(00) 0 0000-0000');
  }

  public getCircularReplacer = () => {
    const seen = new WeakSet();

    return (key, value: any) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) return;

        seen.add(value);
      }

      return value;
    };
  };

}