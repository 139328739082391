import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

import { AdComponent } from './ad/ad.component';
import { InputComponent } from './input/input.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { AllergensComponent } from './view-product/allergens/allergens.component';
import { IngredientsComponent } from './view-product/ingredients/ingredients.component';
import { ProductsPrimaryInfoComponent } from './products-primary-info/products-primary-info.component';
import { DiscardPackagingComponent } from './view-product/discard-packaging/discard-packaging.component';
import { NutritionalTableComponent } from './view-product/nutritional-table/nutritional-table.component';
import { TableNutritionalInfoComponent } from './table-nutritional-info/table-nutritional-info.component';
import { ConfirmDeleteComponent } from './modals/confirm-delete/confirm-delete.component';
import { AttachFileComponent } from './attach-file/attach-file.component';

@NgModule({
  declarations: [
    TableNutritionalInfoComponent,
    ProductsPrimaryInfoComponent,
    DiscardPackagingComponent,
    NutritionalTableComponent,
    IngredientsComponent,
    AllergensComponent,
    AdComponent,
    ContactFormComponent,
    InputComponent,
    ConfirmDeleteComponent,
    AttachFileComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    MatIconModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    MatDialogModule
  ],
  exports: [
    TableNutritionalInfoComponent,
    ProductsPrimaryInfoComponent,
    DiscardPackagingComponent,
    NutritionalTableComponent,
    IngredientsComponent,
    AllergensComponent,
    AdComponent,
    ContactFormComponent,
    InputComponent,
    AttachFileComponent
  ]
})
export class ComponentsModule { }