import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-nutritional-info',
  templateUrl: './table-nutritional-info.component.html',
  styleUrls: ['./table-nutritional-info.component.scss']
})
export class TableNutritionalInfoComponent implements OnInit {

  @Input() data: any;
  @Input() portion: string;
  @Input() additionalInformation: string;

  public headerInfo: any;

  constructor() { }

  ngOnInit(): void {
    this.extractHeaderTable();
  }

  private extractHeaderTable(): void {
    const header = this.data[0];
    this.headerInfo = {
      key_1: header?.key_1 || '',
      key_2: header?.key_2 || '',
      key_3: header?.key_3 || '',
      key_4: header?.key_4 || '',
      enabled_1: header?.enabled_1,
      enabled_2: header?.enabled_2,
      enabled_3: header?.enabled_3,
      enabled_4: header?.enabled_4
    }
  }
}
